<template>
  <div class="box">
    <div class="w128">
      <div class="content">
        <div class="left">
          <div class="title">学习中心</div>
          <ul>
            <li
              @click="go('/Layout/Learningcenter')"
              :class="$route.path == '/Layout/Learningcenter' ? 'active' : ''"
            >
              首页
            </li>
            <li
              @click="go('/Layout/mynterpretation')"
              :class="$route.path == '/Layout/mynterpretation' ? 'active' : ''"
            >
              我的班级
            </li>
            <!-- <li
              @click="go('/Layout/reportonlearning')"
              :class="$route.path == '/Layout/reportonlearning' ? 'active' : ''"
            >
              学习报告
            </li> -->
            <!-- <li
              @click="go('/Layout/learningprogress')"
              :class="$route.path == '/Layout/learningprogress' ? 'active' : ''"
            >
              学习进度
            </li> -->
            <li
              @click="go('/Layout/myfavorite')"
              :class="$route.path == '/Layout/myfavorite' ? 'active' : ''"
            >
              我的收藏
            </li>
            <li
              @click="go('/Layout/myinformation')"
              :class="$route.path == '/Layout/myinformation' ? 'active' : ''"
            >
              个人信息
            </li>
            <li
              @click="go('/Layout/myset')"
              :class="$route.path == '/Layout/myset' ? 'active' : ''"
            >
              帐号设置
            </li>
            <li
              @click="go('/Layout/message')"
              :class="$route.path == '/Layout/message' ? 'active' : ''"
            >
              消息中心
            </li>
          </ul>
        </div>
        <div class="rigth"><router-view></router-view></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    // 页面跳转
    go(val, num) {
      this.$router.push(val);
    },
  },
};
</script>

<style lang='scss' scoped>
.box {
  background-color: #f7f7f7;
  overflow: hidden;
}
.w128 {
  width: 1280px;
  margin: 0 auto;
}
.content {
  margin-top: 19px;
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
  .left {
    padding: 0 13px;
    box-sizing: border-box;
    width: 268px;
    // height: 100%;
    background-color: #fff;
    .title {
      padding-left: 13px;
      height: 74px;
      line-height: 74px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      border-bottom: 1px solid #e1e1e1;
    }
    ul {
      .active {
        color: #137cfb !important;
      }
      li {
        &:hover {
          color: #137cfb !important;
        }
        cursor: pointer;
        padding-left: 13px;
        width: 206px;
        height: 60px;
        line-height: 60px;
        background: #ffffff;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
      }
    }
  }
  .rigth {
    width: 992px;
  }
}
</style>